<template>
	<div>
  	<Breadcrumbs :items="crumbs" />
	  <div class="white">
      <v-container>
        <div class="display-3">
          <span class="thin">Our</span>
        </div><br />
        <div class="display-4">
          <span class="thick">Patents</span>
        </div>
      </v-container>
    </div>

    <div class="grey darken-4 white--text">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="headline">Twenty-two utility patents granted from the USPTO covering:</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="grey lighten-4">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="title mb-1">
              Electronic device and its applications
            </div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">A technology stack which allows integrated real-time data tracking & analytics</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Display firmware</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Back-end cloud server technology</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Two additional design patents</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
	data() {
    return {
      name: this.$route.name,
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Our Technology',
          disabled: false,
          exact: true,
          to: '/technology'
        },
        {
          text: 'Patents',
          disabled: true,
          to: ''
        }
      ],
    }
 	},
	components: {
    Nav,
    Breadcrumbs,
    Footer
  },
}
</script>